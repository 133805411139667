import { DiscussionGuidePage, ToolPath } from 'app/constants';
import { DiscussionStatusVal } from 'app/models';
import { AlignReportTabs } from 'app/pages/align/Report/FullAlignReport/AlignReportSteps';
import ThirdPartyService from 'app/services/ThirdPartyService';

import { buildURL, buildURLParams } from '../URLHelpers';
import ApiRoutes from './ApiRoutes';

// Base ADDR (defaults set because tests don't have a window.location property)
const BASE_ADDR = window.location ? `${window.location.protocol}//${window.location.host}` : '';

if (!BASE_ADDR) {
  ThirdPartyService.sentry.captureException(new Error('Base url not found!'));
}

type Params = { [key: string]: string };

const ServerRoutes = {
  aiCoach: {
    login: () => '/aicoach/login',
    chat: () => '/aicoach/chat',
  },

  api: ApiRoutes,

  pulses: {
    report: (memberId: number, pulseId: number) => `/members/${memberId}/pulses/${pulseId}/report`,

    discussionGuide: {
      addPulseHabit: (exerciseId: number, step?: string) =>
        buildURL(`/align/${exerciseId}/discussion-guide/commit/new`, step),
      testDriveAddPulseHabit: (
        exerciseTypeSecretCode: string | number = 'team-foundations',
        step?: string
      ) => buildURL(`/test-drive/${exerciseTypeSecretCode}/discussion-guide/commit/new`, step),
    },
  },

  team: {
    pulseReport: (teamId: number, pulseId: number) => `/teams/${teamId}/pulses/${pulseId}/report`,
    addPulseHabit: (teamId: number, step: string) =>
      buildURL(`/dashboard/teams/${teamId}/teamHabits/new`, step),
    editPulseHabit: (teamId: number, pulse: number, step: string) =>
      buildURL(`/dashboard/teams/${teamId}/teamHabits/edit/${pulse}`, step),
    pulseTestDrive: (token: string, step: string): string =>
      buildURL(`/teams/pulses/test-drive/${token}/${step}`),
  },

  align: {
    onboarding: (step: number) => `/member/onboarding/align/${step}`,
    report: (token: string, page?: AlignReportTabs, params?) =>
      buildURL(`/align/${token}/report`, page, params),
    testDriveReportSummary: (exerciseTypeSecretCode) =>
      `/test-drive/${exerciseTypeSecretCode}/report_summary`,

    testDriveReportPage: (exerciseTypeSecretCode: string, page: string, params?) =>
      buildURL(`/test-drive/${exerciseTypeSecretCode}/report`, page, params),

    reportSummary: (exerciseCode: string, exerciseID: number) =>
      `/teams/${exerciseCode}/${exerciseID}/report_summary`,
    coachReport: (exerciseCode: string, exerciseID: number) =>
      `/teams/${exerciseCode}/${exerciseID}/coach_report`,
    personalReport: (
      exerciseCode: string,
      exerciseID: number,
      memberCode: string,
      memberID: number
    ) => `/teams/${exerciseCode}/${exerciseID}/${memberCode}/${memberID}/report`,
    testDrive: (exerciseTypeSecretCode: string, params = '') =>
      `/test-drive/${exerciseTypeSecretCode}/exercise${params}`,
    testDriveReport: (exerciseTypeSecretCode: string, params = '') =>
      `/test-drive/${exerciseTypeSecretCode}/report_summary${params}`,
    post360Survey: (exerciseTypeSecretCode: string) =>
      `/test-drive/${exerciseTypeSecretCode}/post_360_survey`,
    testDriveDiscussionGuide: (exercise: number) => `/test-drive/${exercise}/discussion-guide`,
    testDriveCommitments: (exerciseTypeSecretCode = 'team-foundations') =>
      `/test-drive/${exerciseTypeSecretCode}/commitments`,

    testDriveOverview: (exerciseTypeSecretCode = 'team-foundations') =>
      `/test-drive/${exerciseTypeSecretCode}/overview`,

    manage: (teamID: number, exerciseID: number, tab?: string) =>
      buildURL(`/align/${teamID}/${exerciseID}/manage`, tab),
    discussionGuide: (exerciseID: number, step = '') => {
      const suffix = step === DiscussionGuidePage.START ? '' : step;
      return buildURL(`/align/${exerciseID}/discussion-guide`, suffix);
    },

    exercise: (token: string, step: number | string = '') => `/align/exercise/${token}/${step}`,

    postAlignReport: (token: string) => `/align/personal/${token}/next_steps`,
  },

  my360: {
    viewResults: (exerciseCode: string, exerciseId: number) =>
      `/my360/${exerciseCode}/${exerciseId}/results`,

    exercise: (token: string, step: number | string = '') => `/my360/exercise/${token}/${step}`,

    report: (token: string, page?: string, params?) =>
      buildURL(`/my360/${token}/report`, page, params),
    reportRespondents: (token: string) => `/my360/${token}/respondents`,

    testDrive: (token: string, step: string, subNav?: string): string =>
      buildURL(`/reflect360/test-drive/${token}`, step, subNav),
  },

  dashboard: {
    aiCoach: () => '/dashboard/ai_coach',
    home: () => '/dashboard/home',
    newTeamGroup: (orgID: number) => `/dashboard/organizations/${orgID}/team_groups/add`,
    manageTeamGroup: (groupID: number) => `/dashboard/team_groups/${groupID}/edit`,
    profile: () => '/dashboard/profile',
    alignWithoutTeam: () => '/dashboard/teams/align',
    habitWithoutTeam: () => '/dashboard/teams/teamHabits',

    perspectiveHomeDefault: (): string => '/dashboard/perspective',
    perspectiveHome: (orgId?: number, teamId?: number, params?: Params) =>
      orgId
        ? buildURL(`/dashboard/perspective/${orgId}`, teamId, params)
        : ServerRoutes.dashboard.perspectiveHomeDefault(),

    teamPage: (teamID: number, page: ToolPath) => `/dashboard/teams/${teamID}/${page}`,
    teamAlign: (teamID?: number, page: ToolPath = ToolPath.TeamAligns, params?: Params) =>
      buildURL(`/dashboard/teams/${teamID}`, page, params),
    teamHabits: (teamID?: number) => `/dashboard/teams/${teamID}/teamHabits`,
    newTeam: (params?) => `/dashboard/teams/new?${buildURLParams(params)}`,

    orgTeamActivities: (orgID: number) => `/dashboard/organizations/${orgID}/team/activities`,
    managerPlaybook: (teamId: number, memberId?: number) =>
      buildURL(`/dashboard/teams/${teamId}/perspective/manager-playbook`, memberId),

    perspectives: {
      teamReport: (teamId: number) => `/dashboard/teams/${teamId}/perspective/team`,
      teamDiscuss: (teamId: number, step?: string) =>
        buildURL(`/perspective/discuss/teams/${teamId}`, step),
    },

    myself: {
      summary: () => '/dashboard/personal_profile',
      settings: () => '/dashboard/profile/settings',
      perspectiveCalendar: () => '/perspective/calendar/enable',
      my360: (memberId?: number) => buildURL('/dashboard/my_360', memberId),
      colleague360: () => '/dashboard/colleague-360s',
      manageMy360: (exerciseId: number, tab?: string) =>
        buildURL('/dashboard/my_360', exerciseId, 'manage', tab),

      personalHabitsNew: (step?: string) => buildURL('/dashboard/my_habits/new', step),
      personalHabitsEdit: (pulseId: number, step?: string): string =>
        buildURL(`/dashboard/my_habits/edit/${pulseId}`, step),

      myHabits: (memberId?: number) => buildURL('/dashboard/my_habits', memberId),
      myPerspective: (memberId?: number) => buildURL('/dashboard/my_perspective', memberId),
    },

    my360: {
      new: (memberId?: number) => buildURL('/dashboard/my_360/new', memberId),
      selfAssessment: (exerciseId: number) => `/dashboard/my_360/${exerciseId}/self_assessment`,
      inviteParticipants: (exerciseId: number) =>
        `/dashboard/my_360/${exerciseId}/invite_participants`,
    },

    manager360: {
      selectTeam: () => '/dashboard/manager-360/select-team',
      selectTemplate: () => '/dashboard/manager-360/select-template',
      summary: () => '/dashboard/manager-360/summary',
    },

    teams: {
      myTeams: () => '/dashboard/my_teams',
      members: (teamId: number) => `/dashboard/teams/${teamId}/members`,
      home: (teamId: number, params?: Params) =>
        buildURL(`/dashboard/teams/${teamId}/home`, params),
      tool: (teamId: number, tool: ToolPath, page?: string, params?: Params) =>
        buildURL('/dashboard/teams', teamId, tool, page, params),
      aligns: (teamId: number, params?: Params) =>
        buildURL(`/dashboard/teams/${teamId}/aligns`, params),
    },

    teamConnect: {
      goToStep: (teamId: number, teamGameId: number, step: string, memberId?: number) =>
        buildURL(`/dashboard/teams/${teamId}/team-games/connect`, teamGameId, step, memberId),
      baseUrl: (teamId: number) => `/dashboard/teams/${teamId}/team-games/connect`,
    },

    organization: {
      summary: (orgId: number) => `/dashboard/organizations/${orgId}/show`,
      reports: (orgId: number) => `/dashboard/organizations/${orgId}/reports`,
      reportView: (orgId: number, reportId: number) =>
        `/dashboard/organizations/${orgId}/reports/${reportId}`,
      pulse: (orgId: number) => `/dashboard/organizations/${orgId}/global-pulses`,
      analytics: (orgId: number) => `/dashboard/organizations/${orgId}/engagement/analytics`,
      teams: (orgId: number) => `/dashboard/organizations/${orgId}/teams`,
      members: (orgId: number) => `/dashboard/organizations/${orgId}/members`,
      roles: (orgId: number) => `/dashboard/organizations/${orgId}/roles`,
      report: (organizationId: number, programId: number) =>
        `/dashboard/organizations/${organizationId}/programs/${programId}/report`,

      reportRollup: (
        orgId: number,
        divisionId?: number,
        subdivisionId?: number,
        exerciseTypeId?: number
      ): string => {
        const segments = [];

        if (divisionId) {
          segments.push(`division/${divisionId}`);
        }

        if (subdivisionId) {
          segments.push(`subdivision/${subdivisionId}`);
        }

        if (exerciseTypeId) {
          segments.push(`template/${exerciseTypeId}`);
        }

        return buildURL(`/dashboard/organizations/${orgId}/reports`, segments.join('/'));
      },
    },

    referrals: {
      referByLink: (referrer) => `/dashboard/referrals?referrer=${referrer}`,
      referByEmail: (referrer) => `/dashboard/referrals/email?referrer=${referrer}`,
    },

    coach: () => '/dashboard/coach',
    myselfDivergence: () => '/dashboard/profile/myself',

    resources: () => '/dashboard/resources',

    myColleagues: () => '/dashboard/my_colleagues',

    subscription: {
      payOrder: (orgId: number, orderId: number) => `/subscriptions/${orgId}/pay-order/${orderId}`,
      billing: (orgId: number) => `/dashboard/profile/billing/${orgId}`,
      viewOrderDetails: (orgId: number, orderId: number) =>
        `/dashboard/profile/billing/${orgId}/orders/${orderId}`,
    },
  },

  support: {
    align: () => 'https://support.valence.co/align',
    facilitatorResources: {
      starterTemplate: '/guides/Shift-Discussion-Guide-Minimal-vF.pdf',
      facilitatorTips: '/guides/Interactive-Discussion-Guide-Accompanying-Deck.pdf',
    },
  },

  perspective: {
    questions: () => '/perspective/questions',

    report: {
      overview: (token?: string) => buildURL('/perspective/report', { token }),
      personal: (params?: Params) => buildURL('/perspective/report/personal', params),
      pairReport: (teamID?: number, otherMemberID?: number, token?: string) =>
        buildURL('/perspective/report/personal/pair', teamID, otherMemberID, { token }),
      personalPairReport: (otherMemberID: number, token?: string) =>
        buildURL('/perspective/report/personal/pair/other', otherMemberID, { token }),
      team: (teamID?: number, token?: string, params?: Params) =>
        buildURL('/perspective/report/team', teamID, { token, ...params }),
      pair: (teamID?: number, otherMemberID?: number, token?: string) =>
        buildURL('/perspective/report/pair', teamID, otherMemberID, { token }),
      pairOther: (otherMemberID?: number) =>
        buildURL('/perspective/report/pair/other', otherMemberID),
      guestPair: (token: string) => `/perspective/report/guest-pair/${token}`,
      discussion: (token?: string) => buildURL('/perspective/report/discussion', { token }),
    },

    pairDimensionReport: (teamId: number, otherMemberId: number, dimension: string) =>
      `/perspective/pair/${teamId}/${otherMemberId}/report/${dimension}`,

    invite: (teamID, teamCode, withTracker = true) => {
      const tracker = '?utm_source=app&utm_medium=web-report&utm_campaign=perspective-invite-link';
      return `${BASE_ADDR}/perspective/invite/${teamID}/${teamCode}${withTracker ? tracker : ''}`;
    },
    inviteOthers: (withTracker = true) => {
      const tracker = '?utm_source=app&utm_medium=web-report&utm_campaign=perspective-invite-link';
      return `${BASE_ADDR}/perspective/invite${withTracker ? tracker : ''}`;
    },
    inviteWizard: (teamID: string | number, ref: string = window.location.pathname) =>
      `/perspective/teams/${teamID}/invite?link_referrer=${ref}`,

    walkThrough: (step: number | string = '') => `/perspective/walk-through/${step}`,
    testDrive: (token: string, step: string) => `/perspective/test-drive/${token}/${step}`,

    takePerspective: () => '/dashboard/my_perspective',
  },

  discussionSpace: {
    show: (discussionSpaceID: number) => `${BASE_ADDR}/discussion-spaces/${discussionSpaceID}`,
    showByToken: (token: string) => `${BASE_ADDR}/discussion-spaces/${token}`,
    gotoType: (exerciseID: number, type: DiscussionStatusVal): string =>
      `/exercise/${exerciseID}/discussion-spaces/${type}`,
  },

  auth: {
    login: () => '/login',
    logout: () => '/logout',
    register: (params?: Params) => buildURL('register', params),
    unimpersonate: () => '/unimpersonate',
    passwordReset: (token?: string, params: { [key: string]: string } = {}) =>
      `${buildURL('/password/reset', token)}?${buildURLParams(params)}`,
  },

  member: {
    onboarding: (step?: string, params?: Params): string =>
      buildURL('/member/onboarding', step, params),
  },

  print: {
    view: (memberID: number, report: string, params?: Params) =>
      buildURL(`/print/${report}/${memberID}/view`, params),
  },

  referrer: (fallback = '/') => {
    if (document.location.href === document.referrer) {
      return fallback;
    }

    return document.referrer;
  },

  admin: {
    novaAdminBase: () => '/admin',
    memberReport: (memberID) => `/admin-panel/user-reports/${memberID}`,
    impersonate: (memberId) => `/admin-panel/impersonate/${memberId}`,
    usageData: () => '/admin-panel/usage-data',
    pulseTemplates: {
      list: () => '/admin-panel/pulse/templates/list',
      create: () => '/admin-panel/pulse/templates/new',
      edit: (templateId: number) => `/admin-panel/pulse/templates/${templateId}/edit`,
    },
    home: () => '/admin-panel/home',
    userReports: () => '/admin-panel/user-reports',
    exerciseTypes: () => '/admin-panel/exercise_types/list',
    organizations: () => '/admin-panel/organizations/list',
    personalHabits: () => '/admin/resources/nova-pulse-for-members',
    teamHabits: () => '/admin/resources/nova-pulse-for-teams',
    perspectives: () => '/admin-panel/perspectives/list',
    discussionGuides: () => '/admin-panel/discussion-guides',
    customLandingPages: () => '/admin-panel/landing_pages/list',
    customBlocks: () => '/admin-panel/custom-blocks',
    customLocalizations: () => '/admin-panel/custom-localizations',
    storybook: () => '/admin-panel/storybook',

    // stats
    activationStats: () => '/admin-panel/activation-stats',
    engagementStats: () => '/admin-panel/engagement-stats',
    totalStats: () => '/admin-panel/total-stats',
    charts: () => '/admin-panel/charts',
    insightStats: () => '/admin-panel/insight-stats',
    orgContent: {
      list: () => '/admin-panel/org-contents',
      edit: (orgId: number) => `/admin-panel/org-contents/${orgId}`,
    },
    securityTools: {
      sessions: () => '/admin-panel/security-tools',
      sessionDetails: (params?: Params) =>
        buildURL('/admin-panel/security-tools/session-details', params),
    },
  },

  agreements: {
    terms: () => '/agreements/terms',
  },

  errors: {
    notFound: () => '/errors/not_found',
  },

  organizations: {
    overviews: {
      align: () => '/organizations/aligns/overview',
      perspective: () => '/organizations/perspective/overview',
      teamHabit: () => '/organizations/team-habits/overview',
      reflect360: () => '/organizations/reflect360/overview',
    },
  },

  analytics: {
    orgContractExport: (
      orgId: number,
      reportExport: string,
      params: { [key: string]: number | string },
      contractOption: number | string
    ) =>
      buildURL(`/data-exports/${orgId}/contracts/${contractOption}/export/${reportExport}`, params),
  },

  programs: {
    programsUsageExport: (onboardingLinkID) =>
      `/onboarding/download/link_usage/${onboardingLinkID}`,
  },
};

export default ServerRoutes;
