import { action, observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelItem, TeamGameModel } from 'app/models';
import ThirdPartyService from 'app/services/ThirdPartyService';
import Store from 'app/stores/Store';

const TEAM_GAME_TEMPLATE = 'team_quiz_game';

interface MemberInfo {
  name: string;
  email: string;
}

export interface TeamGameAnswers {
  question_id: number;
  answer: string;
}

export class TeamGameStore extends Store<TeamGameModel> {
  @observable teamGame = new ModelItem<TeamGameModel>(TeamGameModel);

  @observable isSaving = false;
  @action setIsSaving = (isSaving: boolean): void => {
    this.isSaving = isSaving;
  };

  @observable isUpdating = false;
  @action setIsUpdating = (isUpdating: boolean): void => {
    this.isUpdating = isUpdating;
  };

  constructor() {
    super();
    TeamGameModel._store = this;
  }

  create = async (teamId: number): Promise<TeamGameModel> => {
    this.setIsSaving(true);

    const url = ServerRouteHelper.api.teamGames.create(teamId);
    const config = {
      url,
      data: { template: TEAM_GAME_TEMPLATE },
      showGenericError: true,
    };

    const response = await this.apiService.newPost(config);

    if (!response) {
      return;
    }

    this.teamGame.deserialize(response.data);

    this.setIsSaving(false);

    return TeamGameModel.fromJson(response.data);
  };

  saveAnswers = async (
    teamId: number,
    teamGameId: number,
    answers: TeamGameAnswers[]
  ): Promise<void> => {
    this.setIsSaving(true);

    const url = ServerRouteHelper.api.teamGames.answers(teamId, teamGameId);

    const config = {
      url,
      data: { answers },
      showGenericError: true,
    };

    await this.apiService.newPost(config);

    this.setIsSaving(false);
  };

  saveAnswersByToken = async (
    token: string,
    answers: TeamGameAnswers[],
    member: MemberInfo
  ): Promise<{ member_has_perspective: boolean }> => {
    this.setIsSaving(true);
    try {
      const url = ServerRouteHelper.api.teamGames.byToken(token);

      const config = {
        url,
        data: {
          answers: answers,
          member: member,
        },
        throwError: true,
        showGenericError: true,
      };

      const response = await this.apiService.newPost(config);

      this.setIsSaving(false);

      return response;
    } catch (err) {
      this.setIsSaving(false);
      ThirdPartyService.sentry.captureException(err);
      return Promise.reject(err);
    }
  };

  getById = async (teamId: number, teamGameId: number): Promise<void> => {
    await this.teamGame.load(ServerRouteHelper.api.teamGames.getById(teamId, teamGameId));
  };

  getByToken = async (token: string): Promise<void> => {
    await this.teamGame.load(ServerRouteHelper.api.teamGames.byToken(token));
  };

  updateStatus = async (teamId: number, teamGameId: number, status: string): Promise<void> => {
    this.setIsUpdating(true);

    const url = ServerRouteHelper.api.teamGames.getById(teamId, teamGameId);

    const config = {
      url,
      data: { status },
      showGenericError: true,
      throwError: true,
    };

    const response = await this.apiService.newPatch(config);

    if (response && response.data) {
      this.teamGame.deserialize(response.data);
    }

    this.setIsUpdating(false);
  };
}
export default TeamGameStore;
