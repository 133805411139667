import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import { ModelList } from 'app/models';
import { IntelligenceQuestionModel } from 'app/models/IntelligenceQuestionModel';
import Store from 'app/stores/Store';

export class IntelligenceQuestionStore extends Store<IntelligenceQuestionModel> {
  @observable intelligenceQuestions = new ModelList<IntelligenceQuestionModel>(
    IntelligenceQuestionModel
  );

  constructor() {
    super();
    IntelligenceQuestionModel._store = this;
  }

  public async loadIntelligenceQuestions(memberId: number): Promise<void> {
    const url = ServerRouteHelper.api.intelligence.questions.getQuestions(memberId);

    await this.intelligenceQuestions.load(url);
  }
}

export default IntelligenceQuestionStore;
