import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import { IntelligenceQuestionStore } from 'app/stores/IntelligenceQuestionStore';

export class IntelligenceQuestionModel extends Model {
  public static _store: IntelligenceQuestionStore;

  @observable id: number;
  @observable type: string;
  @observable question: string;
  @observable answer?: string;
  @observable intelligent_thread_id?: number;
  @observable intelligent_strategy_id?: number;
  @observable parent_id?: number;
  @observable created_at: string;
  @observable updated_at: string;

  static fromJson(json: ModelJson): IntelligenceQuestionModel {
    return this._fromJson(json) as IntelligenceQuestionModel;
  }

  static getOrNew(id): IntelligenceQuestionModel {
    return this._getOrNew(id) as IntelligenceQuestionModel;
  }

  static get(id): IntelligenceQuestionModel {
    return this._get(id) as IntelligenceQuestionModel;
  }
}
